import { withCompController } from '@wix/editor-elements-integrations';
import {
  ExpandableMenuControllerProps,
  ExpandableMenuProps,
  ExpandableMenuStateRefs,
} from '../ExpandableMenu.types';

const compController = withCompController<
  ExpandableMenuProps,
  ExpandableMenuControllerProps,
  ExpandableMenuStateRefs
>(({ stateValues, mapperProps }) => {
  const { currentUrl } = stateValues;

  return {
    ...mapperProps,
    currentUrl,
  };
});

export default compController;
